import {
  ApolloClient,
  ApolloLink,
  type ApolloMiddleware,
  createHttpLink,
  type DefaultContext,
  from,
  InMemoryCache,
  type PossibleTypesMap,
} from '~/lazy_apollo/client';
import { SchemaLink } from '~/lazy_apollo/client/link/schema';
import type { DocumentNode } from 'graphql';
import { fetchSubscriptions } from '@shakacode/use-ssr-computation.runtime';
import operationStoreClient from './operationStoreClient';
import schema from '../libs/gql/schema/interfaces.json';
import { initApolloLogsWithTestData } from './testDataLogs';
import { captureSentryMessage } from './sentry';

export const isMutation = (query: DocumentNode) => query.definitions.some(def => 'operation' in def && def.operation === 'mutation');

export const initApollo = ({ ssr, url, operationStoreEnabled }: { ssr: boolean, url: string, operationStoreEnabled: boolean }) => {
  // Attempt to connect to shared client initialized by another Popmenu instance
  if (typeof window !== 'undefined' && window.POPMENU_CLIENT) {
    console.log('[POPMENU] Connecting to shared client...');
    return window.POPMENU_CLIENT;
  }

  // Initialize cache w/ fragment data to handle interfaces
  // https://www.apollographql.com/docs/react/data/fragments/#defining-possibletypes-manually
  const possibleTypes: PossibleTypesMap = {};
  schema.data.__schema.types.forEach((supertype) => {
    if (supertype.possibleTypes) {
      possibleTypes[supertype.name] = supertype.possibleTypes.map(subtype => subtype.name);
    }
  });

  const cache = new InMemoryCache({ possibleTypes });
  if (typeof window !== 'undefined' && window.POPMENU_APOLLO_STATE) {
    cache.restore(window.POPMENU_APOLLO_STATE);
  }

  // Prevent performing http requests when prerendering by providing schema-only link
  if (ssr) {
    return new ApolloClient({
      cache,
      // @ts-expect-error - An unknown hack from the times of the first JS implementation?
      link: new SchemaLink({}), // Server-side schema adapter
      ssrMode: true,
    });
  }

  // Add operation store and http links
  const links: ApolloMiddleware[] = [];
  if (operationStoreEnabled && operationStoreClient) {
    links.push(operationStoreClient.apolloLink);
  }

  const mutationDetectionLink = new ApolloLink((operation, forward) => {
    if (isMutation(operation.query)) {
      fetchSubscriptions();
    }
    return forward(operation);
  });
  links.push(mutationDetectionLink);

  if (process.env.LOG_TEST_DATA_TO_BROWSER_CONSOLE) {
    initApolloLogsWithTestData(links);
  }

  const customFetch: typeof fetch = async (input, init) => {
    if (init?.method === 'GET') {
      // Apollo Client never provides a `Request` as input, only `string` or maybe `URL`
      const fetchUrl = new URL(input as string);
      const operationName = fetchUrl.searchParams.get('operationName') || '';
      const cacheKey = window.popmenuGqlCacheKeys?.[operationName];
      if (!cacheKey) {
        captureSentryMessage(`[POPMENU] Missing cache key for operation ${operationName}`);
        return fetch(fetchUrl, init);
      }

      fetchUrl.searchParams.set('httpCacheKey', cacheKey);
      const query = fetchUrl.searchParams.get('query');
      if (query) {
        // We could use graphql.stripIgnoredCharacters, but this is faster, has most of the benefit,
        // and avoids increased bundle size.
        fetchUrl.searchParams.set('query', query.replace(/\s+/g, ' '));
      }
      return fetch(fetchUrl, init);
    }
    return fetch(input, init);
  };

  if (window.popmenuGqlCacheKeys) {
    const useGetLink = new ApolloLink((operation, forward) => {
      const { operationName, variables } = operation;

      // Will be removed in https://app.shortcut.com/popmenu/story/102146
      const isOrderingEventMenuSectionQuery =
        operationName === 'menuSection' &&
        variables.orderingEventId != null &&
        variables.orderingEventId !== -1;

      if (!window.popmenuGqlCacheKeys?.[operationName] || isOrderingEventMenuSectionQuery) {
        return forward(operation);
      }

      type Context = DefaultContext & {
        fetchOptions: {
          method: 'GET' | 'POST';
        };
        headers: Record<string, string>;
      };

      operation.setContext((prevContext: Context) => ({
        fetchOptions: {
          ...prevContext.fetchOptions,
          method: 'GET',
        },
      }));
      return forward(operation);
    });

    links.push(useGetLink);
  }

  links.push(
    createHttpLink({
      credentials: 'include',
      fetch: window.popmenuGqlCacheKeys && customFetch,
      uri: url,
    }),
  );
  const httpLink = from(links);

  // Initialize client
  console.log('[POPMENU] Init Popmenu Apollo Client');
  const client = new ApolloClient({
    cache,
    link: httpLink,
    ssrMode: false,
  });

  // starts fetching subscriptions when we write to cache
  // In draft mode, the admin web-builder communicates with the consumer app by writing to Apollo cache
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const apolloWriteQuery = client.writeQuery;
  client.writeQuery = (options) => {
    fetchSubscriptions();
    return apolloWriteQuery.call(client, options);
  };

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const apolloWriteFragment = client.writeFragment;
  client.writeFragment = (options) => {
    fetchSubscriptions();
    return apolloWriteFragment.call(client, options);
  };

  if (typeof window !== 'undefined') {
    window.POPMENU_CLIENT = client;
  }
  return client;
};
