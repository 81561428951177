import { isSSR } from '~/utils/dom';
import type { MenuPageEvent, MenuPageState } from '../../types';

const initialViewState: MenuPageState = {
  filters: [],
  focusedSectionId: -1,
  fullyVisibleSections: [],
  loadableSections: [],
  sectionIntersectionsWithRatios: [],
  sectionsWithFilteredItems: [],
  selectedSectionId: null,
};

// Handle view event types here. Cases are determined by existing state value, then type of event in that existing state
export const menuPageReducer = (prevState: MenuPageState = initialViewState, event: MenuPageEvent): MenuPageState => {
  if (isSSR) {
    return prevState;
  }

  if (event.type === 'resetMenuPage') {
    return initialViewState;
  }

  const nextState = { ...prevState };
  switch (event.type) {
    case 'updateFilters': {
      const match = nextState.filters.find(filter => filter.id === event.filter.id);
      if (match) {
        nextState.filters = nextState.filters.filter(filter => filter.id !== match.id);
      } else {
        nextState.filters = [...nextState.filters, event.filter];
      }
      nextState.sectionsWithFilteredItems = [];
      break;
    }
    case 'updateSectionFilteredItems': {
      if (event.hasItems) {
        // Add the new section ID and ensure uniqueness
        nextState.sectionsWithFilteredItems = [
          ...nextState.sectionsWithFilteredItems,
          event.sectionId,
        ].filter((element, index, array) => array.indexOf(element) === index);
      } else {
        // Remove the section ID
        nextState.sectionsWithFilteredItems = nextState.sectionsWithFilteredItems
          .filter(id => id !== event.sectionId);
      }
      break;
    }
    case 'updateSectionIntersections':
      nextState.sectionIntersectionsWithRatios = [...nextState.sectionIntersectionsWithRatios, ...event.sectionIntersections].reduce<MenuPageState['sectionIntersectionsWithRatios']>((accumulator, currentSection) => {
        const matchIndex = accumulator.findIndex(({ sectionId }) => sectionId === currentSection.sectionId);
        if (matchIndex === -1) {
          accumulator.push(currentSection);
        } else {
          accumulator.splice(matchIndex, 1);
          accumulator.push(currentSection);
        }
        return accumulator;
      }, []);
      nextState.fullyVisibleSections = nextState.sectionIntersectionsWithRatios.filter(({ ratio }) => ratio === 1).map(({ sectionId }) => sectionId);
      event.sectionIntersections.forEach((section) => {
        if (!nextState.loadableSections.includes(section.sectionId)) {
          nextState.loadableSections = [...nextState.loadableSections, section.sectionId];
        }
      });
      if (nextState.focusedSectionId !== nextState.selectedSectionId) {
        nextState.focusedSectionId = nextState.sectionIntersectionsWithRatios.reduce((accumulator, currentSection) => (currentSection.ratio >= accumulator.ratio ? currentSection : accumulator), { ratio: 0, sectionId: -1 }).sectionId;
      }
      break;
    case 'scrollSectionIntoViewStarted':
      nextState.selectedSectionId = event.selectedSectionId;
      break;
    case 'scrollSectionIntoViewEnded':
      if (nextState.focusedSectionId !== nextState.selectedSectionId && nextState.selectedSectionId !== null) {
        nextState.focusedSectionId = nextState.selectedSectionId;
      }
      nextState.selectedSectionId = null;
      break;
    case 'fullyVisibleSectionSelected':
      nextState.focusedSectionId = event.selectedSectionId;
      nextState.selectedSectionId = null;
      break;
  }
  return nextState;
};
